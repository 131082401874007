//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins_sunburst from '@amcharts/amcharts4/plugins/sunburst';

import am4themes_dark from '@amcharts/amcharts4/themes/dark';

import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

import { mapGetters, mapActions } from 'vuex';

import eventBus from '@/plugins/eventBus';
import moment from 'moment';
import * as _ from 'underscore';


export default {
  name: 'sessions',
  data() {
    return {
      showChart: true,
      showDuration: false,
      showInnerLoading: false,
      showInnerLoadingSessions: false,
      showInnerLoadingSunbrust: false,
      isCall: false,
      sessions_data: [],
      chart_data: [],
      heatmap_data: [],
      topicData: [],
      busyDay: {
        weekday: 'Monday',
      },
      busyHour: '2 PM',
      dayHoursStats: 0,
      nightHoursStats: 0,
      mostCommonTopic: '',
    };
  },
  computed: {
    ...mapGetters({
      getFilterDate: 'getFilterDate',
      currentAIAccount: 'getCurrentAIAccount',
      currentUser: 'getCurrentUser',
      darkTheme: 'getDarkTheme'
    }),
    totalSessions() {
      if (this.sessions_data.length > 1) {
        return _.find(this.sessions_data, (obj) => obj.rowTitle == 'TOTSESSIONS').Total;
      }
    },
    totalMessages() {
      if (this.sessions_data.length > 1) {
        return _.find(this.sessions_data, (obj) => obj.rowTitle == 'TOTMESSAGES').Total;
      }
    },
    noOfDays() {
      const start = moment(this.getFilterDate.start, 'DD-MM-YYYY');
      const end = moment(this.getFilterDate.end, 'DD-MM-YYYY');
      return end.diff(start, 'days') + 1;
    },
    averageDailySesion() {
      if (this.sessions_data.length > 0) {
        return (this.totalSessions / this.noOfDays).toFixed(2);
      }
      return 0;
    },
    averageMessagePerSession() {
      if (this.sessions_data.length > 0) {
        return (this.totalMessages / this.totalSessions).toFixed(2);
      }
      return 0;
    }
  },
  methods: {
    ...mapActions({
      requestConversationsTopicParameters: 'requestConversationsTopicParameters',
      requestSessionsOverview: 'requestSessionsOverview',
    }),
    generateHeatmapLegendChart() {
      const that = this;
      if (this.heatChart) {
        this.heatChart.dispose();
      }
      const chart = am4core.create(
        this.$refs.HeatmapLegendChart,
        am4charts.XYChart,
      );
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark)
      }
      chart.maskBullets = false;

      const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

      xAxis.dataFields.category = 'weekday';
      yAxis.dataFields.category = 'hour';

      xAxis.renderer.grid.template.disabled = true;
      xAxis.renderer.minGridDistance = 40;

      yAxis.renderer.grid.template.disabled = true;
      yAxis.renderer.inversed = true;
      yAxis.renderer.minGridDistance = 30;

      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = 'weekday';
      series.dataFields.categoryY = 'hour';
      series.dataFields.value = 'value';
      series.sequencedInterpolation = true;
      series.defaultState.transitionDuration = 3000;

      const bgColor = new am4core.InterfaceColorSet().getFor('background');

      const columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 1;
      columnTemplate.strokeOpacity = 0.2;
      columnTemplate.stroke = bgColor;
      columnTemplate.tooltipText = "{weekday}, {hour}: {value.workingValue.formatNumber('#.')}";
      columnTemplate.width = am4core.percent(100);
      columnTemplate.height = am4core.percent(100);

      series.heatRules.push({
        target: columnTemplate,
        property: 'fill',
        min: am4core.color(bgColor),
        max: chart.colors.getIndex(0),
      });

      chart.data = that.heatmap_data;
      // Enable export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = `EVO_Dynamics_report-${that.currentAIAccount.name}-Users Sessions_Heatmap-${that.getFilterDate.start}-to-${that.getFilterDate.end}`;
      that.heatChart = chart;
    },
    generateSunburstChartDetail() {
      const that = this;
      if (this.sunburstChartDetail) {
        this.sunburstChartDetail.dispose();
      }
      if(this.darkTheme === true){
        am4core.unuseTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark);
      }
      // Create a container
      const container = am4core.create(that.$refs.sunburstChartDetial, am4core.Container);
      container.width = am4core.percent(100);
      container.height = am4core.percent(95);

      // create chart
      const chart = container.createChild(am4plugins_sunburst.Sunburst);

      chart.padding(0, 0, 0, 0);
      chart.radius = am4core.percent(90);
      chart.data = that.Sunburst_chart_detial_data;
      chart.colors.step = 2;
      chart.fontSize = 11;
      chart.innerRadius = am4core.percent(10);

      // define data fields
      chart.dataFields.value = 'value';
      chart.dataFields.name = 'name';
      chart.dataFields.children = 'children';

      const level0SeriesTemplate = new am4plugins_sunburst.SunburstSeries();
      level0SeriesTemplate.hiddenInLegend = false;
      chart.seriesTemplates.setKey('0', level0SeriesTemplate);

      // this makes labels to be hidden if they don't fit
      level0SeriesTemplate.labels.template.truncate = true;
      level0SeriesTemplate.labels.template.hideOversized = true;

      level0SeriesTemplate.labels.template.adapter.add('rotation', (rotation, target) => {
        target.maxWidth = target.dataItem.slice.radius - target.dataItem.slice.innerRadius - 10;
        target.maxHeight = Math.abs(((target.dataItem.slice.arc * (target.dataItem.slice.innerRadius + target.dataItem.slice.radius)) / 2) * am4core.math.RADIANS);
        return rotation;
      });

      const level1SeriesTemplate = level0SeriesTemplate.clone();
      chart.seriesTemplates.setKey('1', level1SeriesTemplate);
      level1SeriesTemplate.fillOpacity = 0.75;
      level1SeriesTemplate.hiddenInLegend = true;

      const level2SeriesTemplate = level0SeriesTemplate.clone();
      chart.seriesTemplates.setKey('2', level2SeriesTemplate);
      level2SeriesTemplate.fillOpacity = 0.5;
      level2SeriesTemplate.hiddenInLegend = true;

      that.sunburstChartDetail = chart;
      // Enable export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = `EVO_Dynamics_report-${that.currentAIAccount.name}-Parameters Overview-${that.getFilterDate.start}-to-${that.getFilterDate.end}`;
    },
    createBarChartSessions() {
      const that = this;
      if(this.sessionChart){
        this.sessionChart.dispose()
      }
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark)
      }
      // Create a container
      const container = am4core.create(this.$refs.sessionChart, am4core.Container);

      container.width = am4core.percent(100);
      container.height = am4core.percent(100);
      container.layout = 'vertical';

      // Create chart instance
      const chart = container.createChild(am4charts.XYChart);

      chart.scrollbarX = new am4core.Scrollbar();
      chart.doubleClickDuration = 1200;
      // Add data
      chart.data = that.chart_data;
      chart.scrollbarX = false;

      // Create axes
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'start_time';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.labels.template.adapter.add('text', ( text, target,) => '');
      categoryAxis.tooltip.disabled = true;
      categoryAxis.renderer.minHeight = 0;
      categoryAxis.hide = true;

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      // Create series
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.sequencedInterpolation = true;
      series.colorField = 'color';
      series.dataFields.valueY = 'sessions';
      series.dataFields.categoryX = 'start_time';
      series.tooltipText = '[{categoryX}: bold]{valueY}[/]';
      series.columns.template.strokeWidth = 1;
      // series.columns.template.fill = am4core.color("#ff4444");

      series.tooltip.pointerOrientation = 'vertical';

      series.columns.template.column.cornerRadiusTopLeft = 2;
      series.columns.template.column.cornerRadiusTopRight = 2;
      series.columns.template.column.fillOpacity = 0.8;
      let chatsData = that.chart_data
      // chart series bar width
      if (chatsData.length < 10 && chatsData.length > 8) {
        series.columns.template.width = am4core.percent(50);
      } else if (chatsData.length < 8 && chatsData.length > 6) {
        series.columns.template.width = am4core.percent(40);
      } else if (chatsData.length < 6 && chatsData.length > 4) {
        series.columns.template.width = am4core.percent(30);
      } else if (chatsData.length < 4 && chatsData.length > 2) {
        series.columns.template.width = am4core.percent(20);
      } else if (chatsData.length < 2) {
        series.columns.template.width = am4core.percent(10);
      }
      // on hover, make corner radiuses bigger
      const hoverState = series.columns.template.column.states.create('hover');
      hoverState.properties.cornerRadiusTopLeft = 0;
      hoverState.properties.cornerRadiusTopRight = 0;
      hoverState.properties.fillOpacity = 1;

      series.columns.template.adapter.add('fill', (fill, target) => chart.colors.getIndex(target.dataItem.index));
      categoryAxis.sortBySeries = categoryAxis.dataFields.category;
      categoryAxis.renderer.inversed = true;
      // disable zoom
      chart.zoomOutButton.disabled = true;

      // disable Scrollbar
      chart.hideXScrollbar = true;
      chart.hideYScrollbar = true;
      // Cursor
      chart.cursor = new am4charts.XYCursor();
      // disable zooming
      chart.cursor.behavior = 'none';
      // disable cursor line
      chart.cursor.lineY.disabled = true;
      chart.cursor.lineX.disabled = true;
      // Disable general cursor tootltip for range view
      valueAxis.cursorTooltipEnabled = false;

      // Enable export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = `EVO_Dynamics_report-${that.currentAIAccount.name}-Users Sessions-${that.getFilterDate.start}-to-${that.getFilterDate.end}`;
      that.sessionChart = chart;
    },
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        that.isCall = false;
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: date.start,
          enddate: date.end,
          from: 'onDateUpdate',
        };
        that.updateAISesions(data);
      } else {
        console.log('invalid date');
      }
    },
    updateAISesions(data) {
      const that = this;
      if (!that.isCall) {
        that.showChart = true;
        that.showInnerLoadingSessions = true;
        that.isCall = true;
        if (this.sessionChart) {
          this.sessionChart.dispose();
        }
        if (this.heatChart) {
          this.heatChart.dispose();
        }
        that.updateMostCommonTopic(data);
        that.requestSessionsOverview(data).then((resp) => {
          if (resp.final_data.length > 0) {
            that.sessions_data = resp.final_data;
            that.generateBarChartData(resp.final_data, resp.time_data);
            that.generateHeatMapData(resp.time_data);
            that.generateByDayNighttats(resp.hour_data);
          }
        }).catch((err) => {
          that.showChart = false;
        }).finally(() => {
          setTimeout(() => {
            that.isCall = false;
          }, 1000);
        });
      }
    },
    generateBarChartData(data, time_data) {
      const that = this;
      if (that.getFilterDate.start == that.getFilterDate.end) {
        const chartData = [];
        _.forEach(time_data, (row) => {
          if (row.rowTitle == 'TOTSESSIONS') {
            for (const hour in row) {
              if (hour !== 'rowTitle' && hour !== 'Total') {
                chartData.push({
                  start_time: hour,
                  sessions: row[hour],
                });
              }
            }
          }
        });
        that.chart_data = chartData
        setTimeout(() => {
          that.createBarChartSessions();
        }, 100);
      } else {
        const reject_flags = [
          'TOTSESSIONS',
          'TOTMESSAGES',
          'TOTDURATION',
          "MAXMESSAGES",
          'MONDAY',
          'TUESDAY',
          'WEDNESDAY',
          'THURSDAY',
          'FRIDAY',
          'SATURDAY',
          'SUNDAY',
        ];
        const chartData = [];
        _.forEach(data, (row) => {
          if (!_.contains(reject_flags, row.rowTitle)) {
            chartData.push({
              start_time: moment.unix(row.rowTitle / 1000).format('DD MMM YYYY'),
              sessions: row.Total,
            });
          }
        });
        that.chart_data = chartData
        setTimeout(() => {
          that.createBarChartSessions();
        }, 100);
      }
    },
    updateMostCommonTopic(data) {
      const that = this;
      that.showInnerLoadingSunbrust = true;
      that.requestConversationsTopicParameters(data).then((res) => {
        that.Sunburst_chart_detial_data = res.result;
        that.showInnerLoading = false;
        setTimeout(() => {
          that.generateSunburstChartDetail();
          that.generateTopicsInfo(res.topicData);
          that.showInnerLoadingSunbrust = false;
        }, 100);
        that.mostCommonTopic = _.max(res.topicData, (obj) => obj.value).name || '';
      });
    },
    generateTopicsInfo(topicData) {
      const that = this;
      const topicsTotal = _.reduce(
        _.pluck(topicData, 'value'),
        (memo, num) => memo + num,
        0,
      );
      const sortedValues = _.sortBy(topicData, 'value').reverse();
      _.map(sortedValues, (topic) => {
        topic.percentage = ((topic.value / topicsTotal) * 100).toFixed(2);
        return topic;
      });
      that.topicData = sortedValues;
    },
    generateHeatMapData(time_data) {
      const that = this;
      const heatmap_data_sorted = [];
      const days_flag = [
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
        'SATURDAY',
        'SUNDAY',
      ];
      _.forEach(time_data, (record) => {
        if (_.contains(days_flag, record.rowTitle)) {
          const weekday = record.rowTitle;
          delete record.rowTitle;
          delete record.total;
          for (const hour in record) {
            if (hour !== 'Total') {
              heatmap_data_sorted.push({
                weekday,
                hour,
                value: record[hour],
              });
            }
          }
        }
      });
      that.heatmap_data = heatmap_data_sorted;
      that.generateBusyStats();
      setTimeout(() => {
        that.generateHeatmapLegendChart();
        that.showInnerLoadingSessions = false;
      }, 100);
    },
    generateByDayNighttats(hour_data) {
      const that = this;
      const hour_dataObj = hour_data[0];
      const nightHoursStats = [];
      const dayHoursStats = [];
      for (const hourNumber in hour_dataObj) {
        if (hourNumber !== 'rowTitle') {
          if (hourNumber >= 9 && hourNumber < 18) {
            dayHoursStats.push(hour_dataObj[hourNumber]);
          } else {
            nightHoursStats.push(hour_dataObj[hourNumber]);
          }
        }
      }
      that.dayHoursStats = _.reduce(dayHoursStats, (memo, num) => memo + num, 0);
      that.nightHoursStats = _.reduce(nightHoursStats, (memo, num) => memo + num, 0);
    },
    generateBusyStats() {
      const that = this;
      const { heatmap_data } = that;
      const groupByDay = _.groupBy(heatmap_data, (obj) => obj.weekday);
      const groupByHour = _.groupBy(heatmap_data, (obj) => obj.hour);
      const byDayStats = [];
      for (const day in groupByDay) {
        const weekday = day;
        const day_data = _.pluck(groupByDay[day], 'value');
        const sum = _.reduce(day_data, (memo, num) => memo + num, 0);
        byDayStats.push({ weekday, count: sum });
      }
      const busyDay = _.max(byDayStats, (day) => day.count);
      that.busyDay = busyDay;
      const byHourStats = [];
      for (const hour in groupByHour) {
        if (hour !== 'total') {
          const hour_data = _.pluck(groupByHour[hour], 'value');
          const sum = _.reduce(hour_data, (memo, num) => memo + num, 0);
          byHourStats.push({ hour, count: sum });
        }
      }
      const busyHour = _.max(byHourStats, (day) => day.count).hour;
      that.busyHour = busyHour;
    },

    onAccountUpdate(account) {
      const that = this;
      console.log('onaccount', that.getFilterDate);
      if (that.currentAIAccount.id !== account.id) {
        const data = {
          aiAccount: account.id,
          startdate: that.getFilterDate.start,
          enddate: that.getFilterDate.end,
          from: 'onAccountUpdate',
        };
        that.isCall = false;
        that.updateAISesions(data);
      }
    },
    isValid(value) {
      if (
        value === undefined
        || value === null
        || (typeof value === 'object' && Object.keys(value).length === 0)
        || (typeof value === 'string' && value.trim().length === 0)
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    showChart(newVal) {
      const that = this;
      if (newVal == false) {
        if (that.chart) {
          that.chart.dispose();
        }
      }
    },
    darkTheme(newVal) {
      const that = this;
      that.createBarChartSessions();
      that.generateSunburstChartDetail();
      that.generateHeatmapLegendChart()
    }
  },
  mounted() {
    console.log(this.getFilterDate);
    const that = this;
    const data = {
      aiAccount: that.currentAIAccount.id,
      startdate: that.getFilterDate.start,
      enddate: that.getFilterDate.end,
      from: 'mounted',
    };
    that.updateAISesions(data);
  },
  created() {
    eventBus.$on('updateDate', this.onDateUpdate);
    eventBus.$on('updateAIAccount', this.onAccountUpdate);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
};
